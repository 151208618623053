<template>
  <div>
    <div v-if="!geoscriptParsed.error">
      <pre v-html="geoscriptParsed.data" class="pre-wrap"></pre>
    </div>
    <div v-else class="error--text">
      {{ geoscriptParsed.data }}
    </div>
  </div>
</template>

<script>
import { formatGeoscript } from '@/utils/parser';
export default {
  props: {
    geoscript: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      default: null
    }
  },
  computed: {
    geoscriptParsed() {
      try {
        return {
          error: false,
          data: formatGeoscript(this.geoscript, this.metadata)
        };
      } catch (error) {
        return { error: true, data: error.toString() };
      }
    }
  }
};
</script>

<style lang="scss" >
$color-primary: #007cbf;
$color-key: $color-primary;
$color-comment: grey;
$color-style: rgb(21, 142, 21);
$color-style-separator: rgb(63, 69, 25);

$color-geocircle: rgb(12, 165, 195);
$color-geojson: rgb(195, 12, 158);
$color-geoscript: rgb(5, 46, 169);

.gs-key {
  color: $color-key;
}
.gs-style {
  color: $color-style;
}
.gs-comment {
  color: $color-comment;
}
.gs-style-separator {
  color: $color-style-separator;
}

.gs-key-geoscript {
  color: $color-geoscript;
  font-weight: bold;
}
.gs-key-geojson {
  color: $color-geojson;
}
.gs-key-geocircle {
  color: $color-geocircle;
}

.gs-repeated {
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: red;
}

.gs-not-found {
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: red;
}
.pre-wrap {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
